import React from 'react';

const ImIcon = class extends React.Component {
	render() {
		return (
			<svg fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg" viewBox="4 5 24 24">
				<path d="M28,13.5v7a3.50487,3.50487,0,0,1-3,3.46436V26.5a1.93406,1.93406,0,0,1-1.13281,1.88574,1.733,1.733,0,0,1-.665.1333,2.09422,2.09422,0,0,1-1.46973-.66552L17.87891,24H12.5a3.45466,3.45466,0,0,1-.50006-.05054L14.94971,21h4.17138L22,23.87891V21h2.5a.50641.50641,0,0,0,.5-.5V10.05054A3.495,3.495,0,0,1,28,13.5ZM14.12109,19l-3.85351,3.85352a2.09429,2.09429,0,0,1-1.46973.66552,1.733,1.733,0,0,1-.665-.1333A1.93406,1.93406,0,0,1,7,21.5V18.96436A3.50487,3.50487,0,0,1,4,15.5v-7A3.50424,3.50424,0,0,1,7.5,5h12A3.50424,3.50424,0,0,1,23,8.5v7A3.50424,3.50424,0,0,1,19.5,19Zm-1.24218-3H19.5a.50641.50641,0,0,0,.5-.5v-7a.50641.50641,0,0,0-.5-.5H7.5a.50641.50641,0,0,0-.5.5v7a.50641.50641,0,0,0,.5.5H10v2.87891Z"/>
			</svg>
		);
	}
}

export default ImIcon;
