import React from 'react';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "reactstrap";
import "./Tech.css";
import TextLabel from "../Controls/TextLabel.mjs";
// @ts-ignore
import RegEx from "../media/images/regex.png";
// @ts-ignore
import Dependency from "../media/images/dependency.png";

interface TechProps {

}

interface TechState extends TechProps {
	open: string;
	uid: string;
}

const G = window.glob;
const myName = 'Tech';

const Tech = class extends React.Component<TechProps, TechState> {
	constructor(props:TechProps) {
		super(props);
		this.state = {
			open: G.getCurrentPage()?.params?.section ?? '-',
			uid: G.generateUid(myName)
		};
		this.toggle = this.toggle.bind(this);
	}
	componentDidMount() {
		G.register( 'currentPage', (m ) => {
			this.setState({ open: m?.params?.section ?? '-' });
		}, this.state.uid);
	}
	componentWillUnmount() {
		G.deregister(this.state.uid);
	}
	toggle(pid:string|null) {
		if ( this.state.open === pid ) {
			G.rmCurrentPageParam('section');
		} else {
			G.setCurrentPageParam('section', pid );
		}
	}
	render() {
		return(
			<div className={myName} id={this.state.uid}>
				<img src={RegEx} alt="RegEx" lang={"en"} className={"MainSideImage"}/>
				<img src={Dependency} alt="Dependency" lang={"it"} className={"MainSideImage"}/>
				<h3 className={"mot"}>
					« KISS » : Keep It Simple, Stupid!
				</h3>
				<div lang={"it"}>
					Sotto il profilo tecnico ho le mie preferenze, ovviamente<br/>
					Queste preferenze derivano solo in minima parte dai miei gusti personali,
					ma soprattutto da una lunga esperienza sia nelle scelte che nelle alternative scartate.
				</div>
				<div lang={"en"}>
					From a technical point of view, I’ve my preferences, of course.<br/>
					Such preferences are based only in a minimal part on my personal tastes, but above all on a long
					experience both in the choices and in the discarded alternatives.
				</div>
				<Accordion open={this.state.open} toggle={this.toggle}>
					<AccordionItem>
						<AccordionHeader targetId={'os'}>
							<span lang={"it"}>Sistemi operativi</span>
							<span lang={"en"}>Operating Systems</span>
						</AccordionHeader>
						<AccordionBody accordionId={'os'}>
							<div>
								<div>
									<TextLabel value={"smile-ok"} lang={"icon"}/>
								</div>
								<div>
									<p>Debian Linux</p>
									<p lang={"it"}>
										La scelta migliore per mettere in produzione un server, il più
										affidabile. È anche decente come client.
									</p>
									<p lang={"en"}>
										Best choice for production environment servers, very affordable. It’s
										a good client, too.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-ok"} lang={"icon"}/>
								</div>
								<div>
									<p>MacOS</p>
									<p lang={"it"}>
										La scelta migliore come client e per sviluppare, l’unico ambiente *nix
										dove possono girare senza problemi sia la suite Adobe che M$ Office.<br/>
										Può funzionare decentemente da server di sviluppo, anche meglio se fa girare
										un server Debian con Vmware.
									</p>
									<p lang={"en"}>
										Best choice for clients and developers, the only *nix environment
										where you can run seamlessly both Adobe suite and M$ Office.<br/>
										It’s also a decent development server, even better if it runs a Debian
										server using Vmware.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-avg"} lang={"icon"}/>
								</div>
								<div>
									<p>Ubuntu Linux</p>
									<p lang={"it"}>
										Una scelta decente come client, in più trattandosi di un
										sistema <em>debianish</em> funziona anche bene come server di sviluppo.
									</p>
									<p lang={"en"}>
										A good choice for clients, in addition it’s a <em>debianish</em> system
										and can play well as development server.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-no"} lang={"icon"}/>
								</div>
								<div>
									<p>RedHat Linux</p>
									<p lang={"it"}>
										Viene spesso utilizzato a livello aziendale proprio perché non è free,
										le aziende credono che pagando avranno qualcosa in più.<br/>
										In realtà il package manager e la distribuzione di RH lasciano molto
										a desiderare.
									</p>
									<p lang={"en"}>
										Incs often use it because it isn’t free, and companies believe that by
										paying they’ll receive a better product.<br/>
										Actually both the package manager and the whole distro have issues.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-never"} lang={"icon"}/>
								</div>
								<div>
									<p>
										<TextLabel value={"windows"} lang={"icon"}/>
									</p>
									<p lang={"it"}>
										La scelta peggiore, sempre.<br/>
										Peggior client, peggior server, il TCO più alto.
										È apprezzato solo da chi è vittima della sindrome di Stoccolma.
									</p>
									<p lang={"it"}>
										Da considerare solo se si ha la necessità di far girare software che lo
										richiedono espressamente, ma in quel caso va messa in dubbio la qualità
										di un simile software.
									</p>
									<p lang={"en"}>
										Worst choice for whatever purpose.<br/>
										Worst client, worst server, highest TCO.
										It’s appreciated only by those who are victims of Stockholm syndrome.
									</p>
									<p lang={"en"}>
										Makes sense only if it’s necessary to run some specific software, in this
										case we should also question the quality of such software.
									</p>
								</div>
							</div>
							<h3 className={"mot quote"}>
								<span lang={"it"}>
									Sotto <TextLabel value={"windows"} lang={"icon"}/> l’unica cosa che gira sono le…
								</span>
								<span lang={"en"}>
									Using <TextLabel value={"windows"} lang={"icon"}/> nothing should run
									but you.
								</span>
							</h3>
						</AccordionBody>
					</AccordionItem>
					<AccordionItem>
						<AccordionHeader targetId={'cloud'}>
							<span lang={"it"}>Cloud, Macchine virtuali, Server fisici</span>
							<span lang={"en"}>Cloud, Virtual machines, Bare metal</span>
						</AccordionHeader>
						<AccordionBody accordionId={'cloud'}>
							<div lang={"it"}>
								<h3 className={"mot quote"}>
									Non esiste un “cloud”, si tratta del computer di qualcun altro.
								</h3>
							</div>
							<div lang={"en"}>
								<h3 className={"mot"}>
									— Daddy, what are clouds made of?<br/>
									— Linux servers, mostly.
								</h3>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-ok"} lang={"icon"}/>
								</div>
								<div>
									<p>
										Bare metal <span lang={"it"}>(server fisici)</span>
									</p>
									<p lang={"it"}>
										All’inizio tante realtà sono passate al cloud.<br/>
										Il trend si è invertito, molti tornano al “bare metal”
										per motivi principalmente di costo e prestazioni.
									</p>
									<p lang={"en"}>
										Initially a lot of companies migrated to cloud.<br/>
										The trend is now reversed, many are getting back to bare metal
										because of costs and performances.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-ok"} lang={"icon"}/>
								</div>
								<div>
									<p>
										<span lang={"it"}>Virtualizzazione</span>
										<span lang={"en"}>Virtualization</span>
									</p>
									<p lang={"it"}>
										La virtualizzazione rappresenta un’ottima scelta per la gestione dei server
										bare-metal “in-house”, specialmente se è basata su prodotti come vmware.
									</p>
									<p lang={"en"}>
										Virtualization is actually an excellent choice to manage <em>in-house</em> bare
										metal
										servers, especially if based on products like vmware.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-no"} lang={"icon"}/>
								</div>
								<div>
									<p>AWS</p>
									<p lang={"it"}>
										La promessa del Cloud era semplificare e rendere meno cari i servizi.<br/>
										La realtà è diventata più complessa, meno controllabile… e ogni bit viene
										pagato.
									</p>
									<p lang={"en"}>
										Cloud’s promise was to make things easier and cheaper.< br/>
										Actually it’s more complex, less under control… and everything is billed.
									</p>
								</div>
							</div>
							<div>
								<div>
									<TextLabel value={"smile-never"} lang={"icon"}/>
								</div>
								<div>
									<p>Azure</p>
									<p lang={"it"}>
										Vale quanto detto per AWS, in più… è Microsoft.
									</p>
									<p lang={"en"}>
										It’s the same as per the above AWS, furthermore… it’s Microsoft.
									</p>
								</div>
							</div>
							<h3 lang={"en"} className={"mot quote"}>There’s no such thing as “cloud”, it’s just someone
								else’s computer.</h3>
						</AccordionBody>
					</AccordionItem>
				</Accordion>
			</div>
		);
	}
}

export default Tech;
