if (!window.glob?.prop?.loadedComponents?.core) {

	if (typeof window.glob !== "object") window.glob = {};
	const glob = window.glob;
	if (typeof glob.prop !== "object") glob.prop = {};
	if (typeof glob.prop.registered !== "object") glob.prop.registered = {};
	if (typeof glob.prop.status !== "object") glob.prop.status = {};
	if (typeof glob.prop.data !== "object") glob.prop.data = {};
	if (typeof glob.prop.loadedComponents !== "object") glob.prop.loadedComponents = {};

	// -----------------------------------------------------------------------------------------------------------------
	glob.prop.isDeploy = window.location.port ? (parseInt(window.location.port) < 1024) : true;
	glob.prop.keyApplicationName = 'XBOW';
	glob.prop.uri = {server: ""};
	glob.prop.uri.root = '/';
	glob.prop.uri.localpath = window.location.pathname;
	glob.prop.uri.web = glob.prop.uri.server + glob.prop.uri.localpath;
	glob.prop.appRootNode = document.getElementById("appRootNode");
	glob.prop.homePage = {name: "Home"};
	// -----------------------------------------------------------------------------------------------------------------

	glob.generateUid = ( prefix = "unk") => {
		return prefix + "-" + ("10000000-1048-4000-8000-1400800110084").replace(/[018]/g, c =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	};

	glob.clone = (x) => {
		if (typeof x === "undefined") return undefined;
		if ( x && typeof x === 'object' ) try {
			return window.structuredClone ? window.structuredClone(x) : JSON.parse(JSON.stringify(x));
		} catch (e) { }
		return x;
	};

	glob.getAppWebRoot = () => { return glob.prop.uri.localpath; }

	glob.register = (contexts, trigger, uuid) => {
		if (typeof contexts === 'string') contexts = [contexts];
		if (typeof trigger !== 'function') {
			glob.unregister(contexts, uuid);
			return;
		}
		const uid = (typeof uuid === 'string') ? uuid : glob.generateUid('Status');
		if (Array.isArray(contexts)) contexts.forEach(c => {
			try {
				if (typeof glob.prop.registered[c] === 'undefined') glob.prop.registered[c] = {};
				glob.prop.registered[c][uid] = trigger;
			} catch (e) {
				console.error("glob.register", e, contexts, uid, trigger);
			}
		});
		if ( uuid && contexts.length === 1) return glob.getStatus(contexts[0]);
		return () => {
			glob.unregister(contexts, uid);
		};
	};
	glob.unregister = (contexts, uid) => {
		if (typeof uid !== 'string') return;
		if (typeof contexts === 'string') contexts = [contexts];
		if (Array.isArray(contexts)) contexts.forEach(c => {
			try {
				if (typeof glob.prop.registered[c] === 'object') delete glob.prop.registered[c][uid];
			} catch (e) {
				console.error("glob.unregister", e, uid, contexts);
			}
		});
	};
	glob.deregister = ( uid ) => {
		if (typeof uid !== 'string') return;
		Object.keys( glob.prop.registered || {} ).forEach( k => {
			delete glob.prop.registered[k][uid];
		});
	} ;
	glob.trigger = async (context, ...args) => {
		if (typeof context !== 'string') return;
		if (glob.prop.registered[context]) Object.entries(glob.prop.registered[context]).forEach(([uid, trigger]) => {
			if (typeof trigger === 'function') setTimeout(() => {
				trigger.apply(undefined, glob.clone(args));
			}, 10);
		});
	};
	glob.getStatus = (s) => {
		let v = glob.prop.status[s];
		return glob.clone(v);
	};
	glob.setStatus = (s, v, store) => {
		if ( store || glob.getStorage(s)) glob.setStorage(s,v);
		glob.prop.status[s] = v;
		glob.trigger(s, v);
	};
	glob.dropStatus = (s, broadcast) => {
		if (typeof s === "string") s = [s];
		if (!Array.isArray(s)) return;
		s.forEach(x => {
			delete glob.prop.status[x];
			glob.setStorage(x,undefined);
			if (broadcast) glob.trigger(x);
			delete glob.prop.registered[x];
		})
	};

	glob.getPref = (key) => {
		const p = glob.getStatus('Prefs') || {};
		return p[key];
	};
	glob.setPref = ( key, value ) => {
		const p = glob.getStatus('Prefs') || {};
		p[key] = value;
		glob.setStatus('Prefs', p, true);
		return value;
	};

	// -----------------------------------------------------------------------------------------------------------------

	glob.incrementRemoteAccessCounter = () => {
		const c = glob.getStatus('remoteAccessCounter') ?? 0;
		glob.setStatus('remoteAccessCounter', c +1 );
	};
	glob.decrementRemoteAccessCounter = () => {
		const c = glob.getStatus('remoteAccessCounter') ?? 1;
		glob.setStatus('remoteAccessCounter', c -1 );
	};

 	// ------- Language --------

	glob.getLanguage = () => glob.getPref('language');

	glob.getLanguageLong = ( lang ) => {
		if ( lang && lang.length === 5 ) return lang;
		if ( typeof lang === 'undefined' ) {
			const ll = glob.getPref('languageLong');
			if ( ll ) return ll;
			lang = glob.getLanguage();
		}
		let longlang;
		switch ( lang ) {
			case 'en': longlang = "en-US"; break;
			default : longlang = lang + '-' + lang.toUpperCase()
		}
		return longlang;
	}

	glob.getDefaultLanguage = () => ( glob.prop.labelsDB?.default );

	glob.setLanguage = ( lang, langLong ) => {
		if ( ! glob.prop.labelsDB ) return setTimeout( ()=>{ glob.setLanguage(lang)} );
		const curlanguage = glob.getPref('language');
		if ( typeof lang === "undefined" && curlanguage ) lang = curlanguage;
		if ( typeof lang === "undefined" ) {
			const navl = glob.getStorage("Prefs")?.language;
			if ( navl ) lang = navl;
		}
		if ( typeof lang === "undefined") {
			const ll = window.navigator.language;
			const navl = ll.substring(0,2).toLowerCase();
			if ( glob.prop.labelsDB[navl] ) {
				lang = navl;
				langLong = ll;
			}
		}
		if ( typeof lang === "undefined") {
			(window.navigator.languages||[]).forEach( cl => {
				if ( typeof lang === "string" ) return;
				const navl = cl.substring(0,2).toLowerCase();
				if ( glob.prop.labelsDB[navl] ) {
					lang = navl;
					langLong = cl;
				}
			});
		}
		if ( lang && ! glob.prop.labelsDB[lang] ) lang = undefined;
		if ( typeof lang === "undefined") {
			const ulangs = (window.navigator.languages||[])
				.filter(x => (x && typeof x === "string") )
				.map( x => x.substring(0,2).toLowerCase() );
			const applangs = Object.keys(glob.prop.labelsDB)
				.filter( x => (x.length === 2) );
			lang = ulangs.find( x => ( applangs.includes(x) ) )||glob.prop.labelsDB.default;
		}
		if ( lang !== curlanguage ) {
			if ( ! langLong || langLong.length !== 5 ) langLong = glob.getLanguageLong( lang  );
			window.document.documentElement.setAttribute('lang',langLong);
			const p = glob.getStorage('Prefs') || {};
			p.language = lang;
			p.languageLong = langLong;
			glob.setStatus('Prefs', p, true);
		}
		return lang;
	};

	glob.loadLabelsData = () => {
		return new Promise( (resolve, reject) => {
			if ( glob.prop.labelsDB ) return resolve(glob.prop.labelsDB);
			window.fetch(
				glob.prop.uri.web + `media/labels.json`,
				{
					method: 'GET',
					cache: 'no-cache',
					headers: {
						'pragma': 'no-cache',
						'cache-control': 'no-cache',
						'Accept': 'application/json',
					},
					referrerPolicy: 'no-referrer'
				}
			).then( response => {
				if ( ! response.ok ) throw response;
				return response.json();
			}).then( jdata => {
				glob.prop.labelsDB = jdata;
				resolve( jdata );
			}).catch( e => {
				reject( e );
			});
		});
	};

	glob.label = ( key, values, lang ) => {
		if (typeof key !== "string" ) return "";
		if ( typeof lang === "undefined" ) lang = glob.getLanguage();
		if ( typeof lang === "undefined" ) return "";
		if ( ! (glob.prop.labelsDB && glob.prop.labelsDB[lang]) ) return "";
		let txt = glob.prop.labelsDB[lang][key] ?? glob.prop.labelsDB[glob.prop.labelsDB.default][key];
		if ( typeof txt === "undefined" ) return key;
		if ( values && typeof values === "object" ) {
			Object.entries( values ).forEach( ([k,v])=> {
				const re = new RegExp('\\{\\{'+k+'(\\|[^}]*)?\\}\\}','g');
				txt = txt.replace( re, v );
			});
		}
		txt = txt.replace(/\{\{[^|}]+\|([^}]*)\}\}/g,"$1");
		return txt;
	};

	glob.icon = ( key ) => glob.label( key, undefined, 'icon' );

	// -------- Storage ------

	glob.getStorageRepo = () => {
		if ( glob.prop.applicationStorage ) return glob.prop.applicationStorage;
		if ( ! (window.localStorage || window.sessionStorage )) return glob.prop.applicationStorage = {};
		if ( typeof glob.prop.storageCanPersist === 'undefined') {
			const ls = window.localStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined' ) glob.prop.storageCanPersist = true;
		}
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			const ls = window.localStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined') return glob.prop.applicationStorage = JSON.parse(ls);
			window.localStorage.setItem(glob.prop.keyApplicationName,'{}');
		} else if ( window.sessionStorage ) {
			const ls = window.sessionStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined') return glob.prop.applicationStorage = JSON.parse(ls);
			window.sessionStorage.setItem(glob.prop.keyApplicationName,'{}');
		}
		return glob.prop.applicationStorage = {};
	};

	glob.getStorage = ( item ) => {
		const s = glob.getStorageRepo();
		return s[item];
	};

	glob.setStoragePersistence = ( persist = false ) => {
		if ( ! (window.localStorage && window.sessionStorage )) return;
		const cs = JSON.stringify(glob.getStorageRepo());
		if ( persist ) {
			window.localStorage.setItem( glob.prop.keyApplicationName, cs );
			window.sessionStorage.removeItem(glob.prop.keyApplicationName);
		} else {
			window.sessionStorage.setItem( glob.prop.keyApplicationName, cs );
			window.localStorage.removeItem(glob.prop.keyApplicationName);
		}
		glob.prop.storageCanPersist = !! persist;
	};

	glob.setStorage = ( item, value ) => {
		if ( ! (window.localStorage || window.sessionStorage )) return;
		const s = glob.getStorageRepo();
		if ( typeof value === 'undefined') {
			delete s[item];
		} else {
			s[item] = value;
		}
		glob.prop.applicationStorage = s;
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			window.localStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		} else  if ( window.sessionStorage ) {
			window.sessionStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		}
	}

	glob.zapStorage = () => {
		if ( ! (window.localStorage || window.sessionStorage )) return;
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			window.localStorage.removeItem(glob.prop.keyApplicationName);
		} else  if ( window.sessionStorage ) {
			window.sessionStorage.removeItem(glob.prop.keyApplicationName);
		}
	}

	// -------- Navigation ------

	glob.getInitialPage = (force) => {
		let op = glob.prop.homePage;
		if (window.location.hash.startsWith("#!/")) {
			const parts = window.location.hash.split("/");
			parts.shift();
			if (parts[0]) {
				op = {'name': parts.shift(), 'params': {}};
				if (parts.length) parts.filter(i => i.includes("=")).forEach(kv => {
					const p = kv.split("=");
					if (p[0]) {
						const k = p.shift();
						const v = p.join("=");
						op.params[k] = v || "";
					}
				});
			}
		}
		return op;
	};

	glob.object2paramsHash = obj => {
		const parts = [];
		if ( obj && typeof obj === "object") Object.keys(obj)
			.filter(k => !!k)
			.filter(k => ! k.startsWith('_'))
			.sort()
			.forEach(k => {
				let v = obj[k];
				v ??= "";
				parts.push(`${k}=${v}`);
			});
		return parts.length ? '/'+parts.join("/") : '';
	};

	glob.setCurrentPage = (op = glob.prop.homePage, force) => {
		if (!op) return;
		if (typeof op === "string") op = {"name": op};
		const newhash = "#!/" + op.name + glob.object2paramsHash(op.params);
		if (newhash !== window.location.hash) {
			window.history.pushState(null, op.name, glob.prop.uri.localpath + newhash);
			glob.setStatus('currentPage', op);
		} else if (force) {
			glob.setStatus('currentPage', op);
		}
	};

	glob.gotoHomePage = () => {
		glob.setCurrentPage()
	};

	glob.setPageByLocation = (force) => {
		glob.setCurrentPage(glob.getInitialPage(), force);
	};

	setTimeout(glob.setPageByLocation, 0);

	window.addEventListener('popstate', () => {
		glob.setPageByLocation(true);
	});

	glob.getCurrentPage = () => {
		let mo = glob.getStatus('currentPage');
		if (mo) return mo;
		return glob.getInitialPage();
	};

	glob.getCurrentPageParams = () => {
		const mo = glob.getCurrentPage();
		return (mo.params || {});
	};

	glob.getCurrentPageParam = (pname) => {
		const mp = glob.getCurrentPageParams();
		return mp[pname];
	};

	glob.setCurrentPageParams = (params) => {
		const mo = glob.getCurrentPage();
		mo.params = params;
		glob.setCurrentPage(mo);
	};

	glob.setCurrentPageParam = (pname, pvalue) => {
		const params = glob.getCurrentPageParams();
		params[pname] = pvalue;
		glob.setCurrentPageParams(params);
	};

	glob.rmCurrentPageParam = (pname) => {
		const params = glob.getCurrentPageParams();
		delete params[pname];
		glob.setCurrentPageParams(params);
	};

	glob.closeAllDialogs = () => {
		document.querySelectorAll('body > .dialogCurtain, body > .dialogBox').forEach(n => {
			n.remove()
		});
	};

	if (true) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const pageParams = Object.fromEntries(urlSearchParams.entries());
		if (pageParams.debug) glob.prop.data.debug = true;
	}

	window.addEventListener( 'resize', () => {
		if ( glob.prop.timeoutWindowResizing ) window.clearTimeout(glob.prop.timeoutWindowResizing);
		glob.prop.timeoutWindowResizing = window.setTimeout( ()=>{
			delete glob.prop.timeoutWindowResizing;
			glob.trigger('windowResized', { width: parseInt(window.innerWidth), height: parseInt(window.innerHeight)});
		}, 100);
	});

	// -----------------------------------------------------------------------------------------------------------------

	if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
		document.body.classList.add('standalone');
		glob.prop.data.standalone = true;
	}

	window.glob.loadLabelsData().then( ()=>{
		window.glob.setLanguage();
		Object.entries( (glob.getStorageRepo()||{}) ).forEach( ([k,v]) => {
			glob.setStatus(k,v);
		});
		glob.prop.loadedComponents.core = true;
	});

	window.debug = (x, level = 0) => {
		x = glob.clone(x);
		switch (level) {
			case 1: level = 'log'; break;
			case 2: level = 'warn'; break;
			case 3: level = 'error'; break;
			default: level = 'debug';
		}
		setTimeout( ()=>{
			switch (level) {
				case 'error': console.error(x); break;
				case 'warn': console.warn(x); break;
				case 'log': console.log(x); break;
				default: console.debug(x);
			}
		});
	}

	// setTimeout( () => {
	// 	Object.entries( (glob.getStorageRepo()||{}) ).forEach( ([k,v]) => {
	// 		glob.setStatus(k,v);
	// 	});
	// }, 100);
}

/* Author: Marco Balestra <balestra@altersoftware.it> - Y-2023 */
